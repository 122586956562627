/* eslint-disable no-useless-concat */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import Cookies from 'universal-cookie';
import { providers, Contract, BigNumber, utils, ethers, Wallet } from 'ethers'
import { FlashbotsBundleProvider, FlashbotsBundleResolution } from "@flashbots/ethers-provider-bundle"
import { useAccount, useNetwork, useSwitchNetwork, useWalletClient } from 'wagmi'
import '../App.css'
import ShitlordFactoryAbi from '../config/ShitlordFactoryAbi.json'
import ShitlordPumpAbi from '../config/ShitlordPumpAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import TextArea from '../components/TextArea.tsx'
import { writeContract, readContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'
import { useWeb3Modal } from '@web3modal/react'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer.jsx'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar.jsx'
import LogoUploadBox from '../components/LogoUploadBox.jsx'
import BannerUploadBox from '../components/BannerUploadBox.jsx'
import { factoryAddress, web3Clients, imageUploadUrl, chainLogos, publicClient, defaultAddress, feeAmounts, supportedChainIds, initialEth, coinNames } from '../utils/constants.ts'

const App = () => {
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork()
  const [logoPreview, setLogoPreview] = useState<string | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [bannerPreview, setBannerPreview] = useState<string | null>(null)
  const [bannerFile, setBannerFile] = useState<File | null>(null)
  const logoFileInput = useRef<HTMLInputElement>(null)
  const bannerFileInput = useRef<HTMLInputElement>(null)
  const { address, isConnected } = useAccount()
  const [provider, setProvider] = useState()
  const [signer, setSigner] = useState()
  const [gasPrice, setGasPrice] = useState()
  const [maxWallet, setMaxWallet] = useState(1)
  const [chainId, setChainId] = useState(1)
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  const [isError, setIsError] = useState('')
  let [loading, setLoading] = useState(false)
  let [creating, setCreating] = useState(false)
  const [website, setWebsite] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')
  const [firstConnect, setFirstConnect] = useState(false)
  const { open } = useWeb3Modal()
  const [depositAmount, setDepositAmount] = useState('')
  const [accountBalance, setAccountBalance] = useState(0)
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAdvanced, setIsCheckedAdvanced] = useState(true);
  const [accounts, setAccounts] = useState([
    { privateKey: '', address: '', balance: '', inputAmount: '', tokenAmount: 0, error: 'Enter Private Key', lp: { ethAmount: 2, tokenAmount: 1073000000 } },
  ]);
  let lp = { ethAmount: 2, tokenAmount: 1073000000 }

  useEffect(() => {
    // const provider = new ethers.providers.Web3Provider(walletClient.transport);
    // const signer = provider.getSigner();
    const { chain, transport } = publicClient
    const network = {
      chainId: chain?.id,
      name: chain?.name,
      ensAddress: chain?.contracts?.ensRegistry?.address
    }
    const provider = new providers.Web3Provider(transport, network)
    const func = async () => {
      const gas = await provider.getGasPrice()
      setGasPrice(gas)
      if (address) {
        let accountBalance = await web3Clients[chainId].eth.getBalance(address)
        accountBalance = web3Clients[chainId].utils.fromWei(accountBalance, 'ether')
        setAccountBalance(accountBalance)
      }
    }
    func()
    const signer = provider.getSigner(address)
    setProvider(provider)
    setSigner(signer)
  }, [chain?.id, address])

  const web3 = web3Clients[chainId];
  const MAX_ROWS = 20;

  const handlePrivateKeyChange = async (index, value) => {
    const newAccounts = [...accounts];
    newAccounts[index].privateKey = value;

    if (!web3.utils.isHexStrict(value) || value.length !== 66) { // 0x + 64 characters
      newAccounts[index].error = 'Invalid private key';
      newAccounts[index].address = '';
      newAccounts[index].balance = '';
    } else {
      try {
        const account = web3.eth.accounts.privateKeyToAccount(value);
        newAccounts[index].address = account.address;

        const balanceWei = await web3.eth.getBalance(account.address);
        newAccounts[index].balance = web3.utils.fromWei(balanceWei, 'ether');
        const gasFee = Number(gasPrice) * 1.5 * 300000 / 10 ** 18
        if (Number(newAccounts[index].balance) < Number(newAccounts[index].inputAmount) + gasFee) {
          newAccounts[index].error = 'Insufficient ETH Balance'
        } else {
          newAccounts[index].error = '';
        }
      } catch (error) {
        console.error('Error:', error);
        newAccounts[index].address = '';
        newAccounts[index].balance = '';
        newAccounts[index].error = 'Error deriving address or balance';
      }
    }

    setAccounts(newAccounts);
  };

  const handleInputAmountChange = (index, value) => {
    const newAccounts = [...accounts];
    newAccounts[index].tokenAmount = value;
    const input = Number(newAccounts[index].lp.ethAmount) * Number(newAccounts[index].lp.tokenAmount) / (Number(newAccounts[index].lp.tokenAmount) - Number(newAccounts[index].tokenAmount)) - Number(newAccounts[index].lp.ethAmount)
    newAccounts[index].inputAmount = input
    if (index !== newAccounts.length - 1) {
      newAccounts[index + 1].lp.ethAmount = Number(newAccounts[index].lp.ethAmount) + input * 99 / 100
      newAccounts[index + 1].lp.tokenAmount = Number(newAccounts[index].lp.tokenAmount) - Number(newAccounts[index].tokenAmount)
    }
    const gasFee = Number(gasPrice) * 1.5 * 300000 / 10 ** 18
    if (input + gasFee > newAccounts[index].balance) {
      newAccounts[index].error = "Insufficient ETH Balance"
    } else {
      newAccounts[index].error = ""
    }
    setAccounts(newAccounts);
    updateAccounts(index + 1)
  };

  const addRow = () => {
    const len = accounts.length
    if (len < MAX_ROWS) {
      setAccounts([...accounts, { privateKey: '', address: '', balance: '', inputAmount: '', tokenAmount: 0, lp: { ethAmount: Number(accounts[len - 1].lp.ethAmount) + Number(accounts[len - 1].inputAmount), tokenAmount: Number(accounts[len - 1].lp.tokenAmount) - Number(accounts[len - 1].tokenAmount) }, error: 'Enter Private Key' }]);
    }
  };

  const removeRow = (index) => {
    const newAccounts = accounts.filter((_, i) => i !== index);
    setAccounts(newAccounts);
  };

  const handleMaxClick = (index) => {
    const newAccounts = [...accounts];
    newAccounts[index].inputAmount = accounts[index].balance;
    setAccounts(newAccounts);
  };

  const isValidAccounts = (accounts) => {
    return accounts.every(account => account.error === "" && account.inputAmount !== '' && Number(account.inputAmount) > 0);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChangeAdvanced = () => {
    setIsCheckedAdvanced(!isCheckedAdvanced);
  };

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  const changeDepositAmount = (value) => {
    setIsError('')
    setDepositAmount(value)
    lp = { ethAmount: 2, tokenAmount: 1073000000 }
    const output = Number(lp.tokenAmount) - Number(lp.ethAmount) * Number(lp.tokenAmount) / (Number(lp.ethAmount) + Number(value))
    const maxAmount = Number(lp.ethAmount) * Number(lp.tokenAmount) / (Number(lp.tokenAmount) - 10 ** 9 * maxWallet / 100) - Number(lp.ethAmount)
    if (output > 10 ** 9 * maxWallet / 100) {
      setIsError(`Exceed max buy limit. Max buy amount: ${maxAmount}`)
    } else {
      lp.ethAmount = Number(lp.ethAmount) + Number(value)
      lp.tokenAmount = Number(lp.tokenAmount) - output
      const newAccounts = [...accounts];
      newAccounts[0].lp.tokenAmount = lp.tokenAmount;
      newAccounts[0].lp.ethAmount = lp.ethAmount;
      setAccounts(newAccounts);
      updateAccounts(0)
    }
  }

  const updateAccounts = (index) => {
    const newAccounts = [...accounts];
    for (let i = index; i < newAccounts.length; i++) {
      const input = Number(newAccounts[i].lp.ethAmount) * Number(newAccounts[i].lp.tokenAmount) / (Number(newAccounts[i].lp.tokenAmount) - Number(newAccounts[i].tokenAmount)) - Number(newAccounts[i].lp.ethAmount)
      newAccounts[i].inputAmount = input
      if (i !== newAccounts.length - 1) {
        newAccounts[i + 1].lp.ethAmount = Number(newAccounts[i].lp.ethAmount) + input * 99 / 100
        newAccounts[i + 1].lp.tokenAmount = Number(newAccounts[i].lp.tokenAmount) - Number(newAccounts[i].tokenAmount)
      }
    }
    setAccounts(newAccounts)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onShitlordPumpCreate = async () => {
    try {
      setCreating(true)
      let feeAmount = feeAmounts[chainId];
      if (Number(depositAmount) > 0) {
        let newEthAmount = Number(initialEth[chainId]) + Number(depositAmount)
        let newTokenAmount = 1073 * 10 ** 6 * Number(initialEth[chainId]) / newEthAmount
        let tokenAmount = 1073 * 10 ** 6 - newTokenAmount
        let maxAmount = 1000000000 * maxWallet / 100;
        if (tokenAmount > maxAmount) {
          setCreating(false)
          toast.error(
            "You can't purchase more than " + ' ' + maxAmount.toLocaleString() + ' ' + " tokens"
          )
          return false;
        }
      }
      if (logoFile && bannerFile) {
        const deployerBytes = utils.hexZeroPad(address, 32);
        const timestamp = Math.floor(Date.now() / 1000);
        const timestampBytes = utils.hexZeroPad(utils.hexlify(timestamp));
        const combinedBytes = utils.concat([deployerBytes, timestampBytes]);
        const salt = utils.keccak256(combinedBytes);
        let predictAddress;
        predictAddress = await readContract({
          address: factoryAddress[chainId],
          abi: ShitlordFactoryAbi,
          functionName: 'getShitlordPumpAddress',
          args: [
            address,
            web3Clients[chainId].utils.toWei(String(feeAmount + Number(depositAmount)), 'ether'),
            [
              tokenName,
              tokenSymbol,
              tokenDescription,
              website,
              twitter,
              telegram,
              discord
            ],
            maxWallet.toString(),
            salt
          ],
          chainId: chainId
        })
        let create
        console.log('>>>>>>', Number(gasPrice))
        create = await writeContract({
          address: factoryAddress[chainId],
          abi: ShitlordFactoryAbi,
          functionName: 'createShitlordPump',
          value: web3Clients[chainId].utils.toWei(String(feeAmount + Number(depositAmount)), 'ether'),
          args: [
            [
              tokenName,
              tokenSymbol,
              tokenDescription,
              website,
              twitter,
              telegram,
              discord
            ],
            maxWallet.toString(),
            salt
          ],
          // gasPrice: isCheckedAdvanced ? (Number(gasPrice) * 2).toFixed(0) : Number(gasPrice).toFixed(0),
          chainId: chainId
        })
        let transactionPromises
        if (isCheckedAdvanced) {
          const cookies = new Cookies();
          let refAddress
          if (cookies.get('ref')) {
            if (Web3.utils.isAddress(rot13(cookies.get('ref')))) {
              refAddress = rot13(cookies.get('ref'))
            }
          } else {
            refAddress = defaultAddress
          }

          // Function to check if the contract is deployed
          async function checkContractDeployed(address) {
            const code = await provider.getCode(address);
            return code !== '0x';
          }

          // Function to wait until the contract is deployed
          async function waitForContractDeployment(address, interval = 1000) {
            while (true) {
              const isDeployed = await checkContractDeployed(address);
              if (isDeployed) {
                console.log('Contract is deployed!');
                return true;
              } else {
                console.log('Contract not deployed yet, waiting...');
                await new Promise(resolve => setTimeout(resolve, interval)); // Wait for the specified interval before checking again
              }
            }
          }

          await waitForContractDeployment(predictAddress);

          transactionPromises = accounts.map(async (account) => {
            const wallet = new Wallet(account.privateKey, provider)
            const pumpContract = new Contract(predictAddress, ShitlordPumpAbi, wallet)
            const inputAmountInWei = web3Clients[chainId].utils.toWei(String(account.inputAmount), 'ether')
            const tx = await pumpContract.buyToken(refAddress, 
              { 
                value: inputAmountInWei, 
                gasPrice: (Number(gasPrice) * 1.5).toFixed(0), 
                gasLimit: 300000 
              })
            return await tx.wait()
          })
        }
        await waitForTransaction({
          hash: create.hash
        })
        await Promise.all(transactionPromises)
        let funAddresses;
        funAddresses = await readContract({
          address: factoryAddress[chainId],
          abi: ShitlordFactoryAbi,
          functionName: 'getAllAddresses',
          chainId: chainId
        })
        let presaleAddress
        if (funAddresses)
          presaleAddress = funAddresses[funAddresses.length - 1];
        let logoUrl
        let bannerUrl
        if (logoFile) {
          const formData = new FormData()
          formData.append('file', logoFile, presaleAddress)
          fetch(imageUploadUrl + 'api/logoUploads', {
            method: 'POST',
            body: formData
          })
            .then(async res => {
              logoUrl = await res.json()
              logoUrl = logoUrl.fileInfo.filename
              if (bannerFile) {
                const formData = new FormData()
                formData.append('file', bannerFile, presaleAddress)
                fetch(imageUploadUrl + 'api/bannerUploads', {
                  method: 'POST',
                  body: formData
                })
                  .then(async res => {
                    bannerUrl = await res.json()
                    bannerUrl = bannerUrl.fileInfo.filename
                    toast.success(
                      `Successfully ${tokenName} Shitlord Pump created`
                    )
                    const link = `/buy/?chain=${chainId}&address=${presaleAddress}`
                    window.location.href = link
                  })
                  .catch(error => {
                    setCreating(false)
                    console.error('Error:', error)
                  })
              }
            })
            .catch(error => {
              setCreating(false)
              console.error('Error:', error)
            })
        }
        setCreating(false)
      } else {
        setCreating(false)
        toast.error(
          'please upload correct image file'
        )
      }
    } catch (err) {
      setCreating(false)
      console.error(err)
      toast.error(
        'There is a problem with your Shitlord Pump create. Please try again later'
      )
    }
  }

  const [, setImageLogoFile] = useState(null)

  const handleImageLogoChange = file => {
    setImageLogoFile(file)
  }

  const [, setImageBannerFile] = useState(null)

  const handleImageBannerChange = file => {
    setImageBannerFile(file)
  }

  const LogoImageUpload = ({ onChange, className, style }) => {
    const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files![0]
      setLogoFile(selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (logoFileInput.current) {
        logoFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={logoFileInput}
          accept="image/*"
          onChange={handleLogoImageChange}
          style={{ display: 'none' }}
        />
        <LogoUploadBox
          imageUrl={logoPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  const BannerImageUpload = ({ onChange, className, style }) => {
    const handleBannerImageChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const selectedFile = e.target.files![0]
      setBannerFile(selectedFile)
      setBannerPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (bannerFileInput.current) {
        bannerFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={bannerFileInput}
          accept="image/*"
          onChange={handleBannerImageChange}
          style={{ display: 'none' }}
        />
        <BannerUploadBox
          imageUrl={bannerPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }
  const setMaxWalletAmount = value => {
    setMaxWallet(value)
  }

  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="max-w-7xl m-auto pt-36 pb-24 px-4 sm:px-12 sm:py-10">
              <section className="lg:mx-auto pt-8 lg:py-[30px] w-full lg:w-[741px] min-w-0">
                <>
                  <section>
                    <section className="my-4">
                      <p className="ContractContentTextTitle h1">
                        Create Token
                      </p>
                    </section>
                    <div className="flex flex-col justify-center items-center gap-[10px] bg-[#27462b] rounded-[25px] py-[45px] px-3 sm:px-[25px]">
                      <div className="text-[#f3cc2f] w-[90%] text-[18px]">Token Info</div>
                      <>
                        <section className="flex flex-col gap-4 w-[90%]">
                          <div className="LpBalance">
                            <p className="Text1">
                              Name<span style={{ color: 'red' }}>*</span>
                            </p>
                          </div>
                          <section className="inputPanel">
                            <section className="inputPanelHeader">
                              <Input
                                placeholder="Enter Name"
                                label=""
                                type="text"
                                changeValue={setTokenName}
                                value={tokenName}
                              />
                            </section>
                          </section>
                        </section>

                        <section className="flex flex-col gap-4 w-[90%]">
                          <div className="LpBalance">
                            <p className="Text1">
                              Symbol<span style={{ color: 'red' }}>*</span>
                            </p>
                          </div>
                          <section className="inputPanel">
                            <section className="inputPanelHeader">
                              <Input
                                placeholder="Enter Symbol"
                                label=""
                                type="text"
                                changeValue={setTokenSymbol}
                                value={tokenSymbol}
                              />
                            </section>
                          </section>
                        </section>

                        <section className="flex flex-col sm:flex-row w-[90%]">
                          <section className="flex flex-col gap-4 w-full sm:w-[40%]">
                            <div className="LpBalance">
                              <p className="Text1">
                                Upload Logo
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <LogoImageUpload
                                  onChange={handleImageLogoChange}
                                  className="h-[175px]"
                                  style={undefined}
                                />
                              </section>
                            </section>
                          </section>

                          <section className="flex flex-col gap-4 w-full sm:w-[60%]">
                            <div className="LpBalance">
                              <p className="Text1">
                                Upload Banner
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <BannerImageUpload
                                  onChange={handleImageBannerChange}
                                  className="upload-box-banner"
                                  style={undefined}
                                />
                              </section>
                            </section>
                          </section>
                        </section>

                        <section className="flex flex-col gap-4 w-[90%]">
                          <p className="Text1">
                            Description (Max 1000 characters)
                            <span style={{ color: 'red' }}>*</span>
                          </p>
                          <section className="inputPanel">
                            <section className="inputPanelHeader">
                              <TextArea
                                rows={6}
                                placeholder="Enter Token Description"
                                changeValue={setTokenDescription}
                                value={tokenDescription}
                              />
                            </section>
                          </section>
                        </section>
                        <div className='flex flex-col sm:flex-row sm:justify-between w-[90%] gap-2'>
                          <section className="flex flex-col gap-4 w-[90%] sm:w-[45%]">
                            <div className="LpBalance">
                              <p className="Text1">
                                Max Wallet (%)
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <div className="fairlaunch-allocation-buttons-container">
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setMaxWalletAmount(1)
                                    }}
                                    style={
                                      maxWallet === 1
                                        ? {}
                                        : { background: 'transparent', color: '#f3cc2f', border: 'solid #f3cc2f 1px' }
                                    }
                                  >
                                    1%
                                  </button>
                                  <button
                                    className="fairlaunch-allocation-button"
                                    onClick={() => {
                                      setMaxWalletAmount(2)
                                    }}
                                    style={
                                      maxWallet === 2
                                        ? {}
                                        : { background: 'transparent', color: '#f3cc2f', border: 'solid #f3cc2f 1px' }
                                    }
                                  >
                                    2%
                                  </button>
                                </div>
                              </section>
                            </section>
                          </section>
                          <section className="flex flex-col gap-4 w-[90%] sm:w-[45%]">
                            <div className="LpBalance">
                              <p className="Text1">
                                Network
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <div className="fairlaunch-allocation-buttons-container">
                                  {supportedChainIds.map(id => (
                                    <button
                                      className={`${chainId === id ? 'opacity-100' : 'opacity-30'}`}
                                      onClick={() => {
                                        setChainId(id)
                                      }}
                                    >
                                      <img src={chainLogos[id]} className='w-8' />
                                    </button>
                                  ))}
                                </div>
                              </section>
                            </section>
                          </section>

                        </div>
                        {/* <section className="flex flex-col gap-4 w-[90%]">
                            <div className="LpBalance">
                              <p className="Text1">
                                Router Option
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <div className="fairlaunch-allocation-buttons-container">
                                  <button
                                    className="fairlaunch-allocation-button"
                                    style={
                                      { background: 'transparent', color: '#b04851', border: 'solid #b04851 1px' }
                                    }
                                  >
                                    Trade Joe
                                  </button>
                                </div>
                              </section>
                            </section>
                        </section> */}
                        <section className="flex flex-col gap-4 w-[90%]">
                          <div className="LpBalance">
                            <p className="Text1">
                              Snipe Amount ({coinNames[chainId]})
                            </p>
                          </div>
                          <section className="flex justify-between mb-[10px] w-full">
                            <div className="flex flex-col w-full">
                              <div className={`bg-transparent border ${isError ? 'border-red-500' : 'border-[#f3cc2f]'} rounded-full w-full py-2 px-4 text-white`}>
                                <input
                                  placeholder="Enter Snipe ETH Amount"
                                  label=""
                                  type="number"
                                  onChange={(e) => changeDepositAmount(e.target.value)}
                                  value={depositAmount}
                                />
                              </div>
                              {isError && (
                                <p className="text-red-500 text-sm mt-1">{isError}</p>
                              )}
                            </div>
                          </section>
                        </section>

                        <label className="relative flex  text-[#f3cc2f] items-center p-2 text-[18px] w-[90%]">
                          Add Social Links
                          <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                          <span className="w-12 h-6 flex items-center flex-shrink-0 ml-4 p-1 bg-[#919191] rounded-full duration-300 ease-in-out peer-checked:bg-[#a48d33] peer-checked:after:bg-[#f3cc2f] after:w-6 after:h-6 after:bg-gray-300 after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-4"></span>
                        </label>
                        <div className={`w-[90%] ${isChecked ? 'grid' : 'hidden'} grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4`}>
                          <section className="flex flex-col gap-4">
                            <div className="LpBalance">
                              <p className="Text1">Website</p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <Input
                                  placeholder="https://"
                                  label=""
                                  type="text"
                                  changeValue={setWebsite}
                                  value={website}
                                />
                              </section>
                            </section>
                          </section>

                          <section className="flex flex-col gap-4">
                            <div className="LpBalance">
                              <p className="Text1">Telegram</p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <Input
                                  placeholder="https://"
                                  label=""
                                  type="text"
                                  changeValue={setTelegram}
                                  value={telegram}
                                />
                              </section>
                            </section>
                          </section>
                          <section className="flex flex-col gap-4">
                            <div className="LpBalance">
                              <p className="Text1">Twitter</p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <Input
                                  placeholder="https://"
                                  label=""
                                  type="text"
                                  changeValue={setTwitter}
                                  value={twitter}
                                />
                              </section>
                            </section>
                          </section>
                          <section className="flex flex-col gap-4">
                            <div className="LpBalance">
                              <p className="Text1">Discord</p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <Input
                                  placeholder="https://"
                                  label=""
                                  type="text"
                                  changeValue={setDiscord}
                                  value={discord}
                                />
                              </section>
                            </section>
                          </section>
                        </div>

                        <label className={`${chainId === 1 ? 'flex' : 'hidden'} relative text-[#f3cc2f] items-center p-2 text-[18px] w-[90%]`}>
                          Bundle Option
                          <input type="checkbox" checked={isCheckedAdvanced} onChange={handleCheckboxChangeAdvanced} className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                          <span className="w-12 h-6 flex items-center flex-shrink-0 ml-4 p-1 bg-[#919191] rounded-full duration-300 ease-in-out peer-checked:bg-[#a48d33] peer-checked:after:bg-[#f3cc2f] after:w-6 after:h-6 after:bg-gray-300 after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-4"></span>
                        </label>
                        <div className={`w-[90%] ${isCheckedAdvanced && chainId === 1 ? 'block' : 'hidden'} sm:gap-4`}>
                          <div className="space-y-2">
                            {accounts.map((account, index) => (
                              <div key={index} className="p-4 border border-[#f3cc2f] rounded-[25px] shadow-md flex flex-row justify-bewteen gap-2 sm:gap-4">
                                <div className='flex flex-col w-[85%] gap-2'>
                                  <div className="flex flex-col">
                                    <div className={`w-full border ${account.error ? 'border-red-500' : 'border-[#f3cc2f]'} rounded-full`}>
                                      <input
                                        type="text"
                                        value={account.privateKey}
                                        onChange={(e) => handlePrivateKeyChange(index, e.target.value)}
                                        placeholder="Enter Private Key"
                                        className={`w-full p-2 rounded-md text-white bg-transparent ${account.error ? 'border-red-500' : ''}`}
                                      />
                                    </div>
                                    {account.error && (
                                      <p className="text-red-500 text-sm mt-1">{account.error}</p>
                                    )}
                                  </div>
                                  <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                                    <div className="flex-grow">
                                      <p className="text-white text-sm font-bold">Address: <span className='text-[#f3cc2f] font-light'>{account.address ? account.address.slice(0, 12) + '...' + account.address.slice(-10) : ''}</span></p>
                                    </div>
                                    <div className="flex-grow">
                                      <p className="text-white text-sm font-bold">Balance: <span className='text-[#f3cc2f] text-base font-light'>{account.balance ? Number(account.balance).toLocaleString() : ''} ETH</span></p>
                                    </div>
                                  </div>
                                  <div className="">
                                    {/* <div className="flex items-center gap-2 justify-between py-1 px-2 border border-[#f3cc2f] rounded-full">
                                      <input
                                        type="number"
                                        value={account.inputAmount}
                                        onChange={(e) => handleInputAmountChange(index, e.target.value)}
                                        placeholder="Input Amount"
                                        className="rounded-md bg-transparent border border-[#f3cc2f] text-white"
                                      />
                                      <button
                                        onClick={() => handleMaxClick(index)}
                                        className="bg-[#69ec69] text-[#222] p-1 rounded-full"
                                      >
                                        Max
                                      </button>
                                    </div> */}
                                    <div className='w-full flex flex-row gap-8 items-center justify-between'>
                                      <div className="flex flex-col items-center gap-2 w-full sm:w-[70%]">
                                        <span className="ml-2 text-white text-[12px]">Token Amount: <span className='text-[#f3cc2f] text-[14px]'>{Number(account.tokenAmount).toLocaleString()}</span></span> {/* Show slider value */}
                                        <input
                                          type="range"
                                          min="0"
                                          max={1000000000 * maxWallet / 100}
                                          value={account.tokenAmount}
                                          onChange={(e) => handleInputAmountChange(index, e.target.value)}
                                          className="slider"
                                          style={{
                                            background: `linear-gradient(to right, #4caf50 ${account.tokenAmount * 10000 / maxWallet / 10 ** 9}%, #d3d3d3 ${account.tokenAmount * 10000 / maxWallet / 10 ** 9}%)`,
                                          }}
                                        />
                                      </div>
                                      <div className='flex flex-col sm:flex-row sm:gap-2 text-white text-[12px] items-center'>Cost: <span className='text-[14px] text-[#f3cc2f]'>{Number(account.inputAmount).toLocaleString()}ETH</span></div>
                                    </div>
                                  </div>

                                </div>
                                <div className="flex flex-col items-center sm:flex-row gap-2 w-[15%] justify-center">
                                  {index > 0 && (
                                    <button
                                      onClick={() => removeRow(index)}
                                      className="border border-[#f3cc2f] text-[#f3cc2f] h-10 w-10 rounded-full text-[24px] px-2"
                                    >
                                      -
                                    </button>
                                  )}
                                  {index === accounts.length - 1 && (
                                    <button
                                      onClick={addRow}
                                      className="bg-[#f3cc2f] text-[#222] h-10 w-10 rounded-full text-[24px] px-2 disabled:bg-[#999]"
                                      disabled={accounts.length >= MAX_ROWS || !isValidAccounts(accounts)}
                                    >
                                      +
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                      <>
                        <div className='flex justify-center flex-col w-full mt-8'>
                          {
                            isConnected ?
                              chain?.id === chainId ?
                                (
                                  <button
                                    disabled={
                                      tokenName === '' ||
                                      tokenSymbol === '' ||
                                      tokenDescription === '' ||
                                      logoFile === null ||
                                      bannerFile === null ||
                                      feeAmounts[chainId] + Number(depositAmount) > accountBalance ||
                                      (isCheckedAdvanced && !isValidAccounts(accounts)) ||
                                      isError
                                    }
                                    onClick={onShitlordPumpCreate}
                                    className="CreateButton flex justify-center items-center"
                                  >
                                    {tokenName === '' ||
                                      tokenSymbol === '' ||
                                      tokenDescription === '' ||
                                      logoFile === null ||
                                      bannerFile === null ||
                                      (isCheckedAdvanced && !isValidAccounts(accounts)) ||
                                      isError
                                      ? 'Please Enter Details'
                                      : feeAmounts[chainId] + Number(depositAmount) > accountBalance ? 'Insufficient Balance'
                                        : creating === false
                                          ? 'Create'
                                          :
                                          <ClipLoader
                                            color={'#222'}
                                            loading={creating}
                                            size={30}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                          />}
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="CreateButton"
                                      type="submit"
                                      onClick={() => {
                                        switchNetwork?.(chainId)
                                      }}
                                    >
                                      Switch Network
                                    </button>
                                  </>
                                )
                              :
                              (
                                <>
                                  <button
                                    className="CreateButton"
                                    type="submit"
                                    onClick={() => {
                                      onConnectWallet()
                                    }}
                                  >
                                    Connect
                                    <span className="navWallet"> Wallet</span>
                                  </button>
                                </>
                              )
                          }
                        </div>
                      </>
                      <div
                        className="text-[#f3cc2f] w-[90%] text-[18px]"
                        style={{
                          maxWidth: '90%',
                          margin: '0',
                          fontSize: '14px',
                          textAlign: 'center',
                          width: '100%'
                        }}
                      >
                        <>Launch Cost {`${(feeAmounts[chainId] + Number(depositAmount)).toLocaleString()} ${coinNames[chainId]}`}</>
                      </div>
                    </div>
                  </section>
                </>
              </section>
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default App
