import { createPublicClient, http } from 'viem'
import { mainnet, base, bsc, polygon, optimism, sepolia, holesky } from 'viem/chains'
import Web3 from 'web3'

export const multicallAddress = {
    1: "0xD245D904Fc5C6485e98e562bF8f36D77C91b9Ee8",
    8453: "0xD245D904Fc5C6485e98e562bF8f36D77C91b9Ee8",
    56: "0x057778fdaA8DB32F462C9D2A58B22D84a9a71d00",
    137: "0xD8f737c474FfE99c9893EbD2D2C0Da235BE36408",
    10: "0xD8f737c474FfE99c9893EbD2D2C0Da235BE36408"
};
export const factoryAddress = {
    1: "0xFF4498BeF1486c14BA983bFd69DF8F8b8c975d4F",
    8453: "0xFF4498BeF1486c14BA983bFd69DF8F8b8c975d4F",
    56: "0x15dB1A6Cef55881589cCcA05172aF6608b994c1B",
    137: "0x0c645cE6983Df20477a51E56E5A7a0f5DF810C6F",
    10: "0x0c645cE6983Df20477a51E56E5A7a0f5DF810C6F"
};
export const contractAddress = "0x0000000000000000000000000000000000000000";
export const WETHAddress = {
    1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    8453: "0x4200000000000000000000000000000000000006",
    56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    137: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    10: "0x4200000000000000000000000000000000000006"
};
export const routerAddress = {
    1: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    8453: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    56: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    137: "0xedf6066a2b290C185783862C7F4776A2C8077AD1",
    10: "0x4A7b5Da61326A6379179b40d00F57E5bbDC962c2"
};
export const defaultAddress = "0x0000000000000000000000000000000000000000";

const PROVIDER_URL_ETH = 'https://ethereum-rpc.publicnode.com'
export const web3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_ETH))

const PROVIDER_URL_BASE = 'https://mainnet.base.org'
export const baseWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_BASE))

const PROVIDER_URL_BSC = 'https://bsc-dataseed.binance.org/'
export const bscWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_BSC))

const PROVIDER_URL_OP = 'https://optimism.llamarpc.com'
export const opWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_OP))

const PROVIDER_URL_POL = 'https://polygon.llamarpc.com'
export const polWeb3Client = new Web3(new Web3.providers.HttpProvider(PROVIDER_URL_POL))

export const web3Clients = {
    8453: baseWeb3Client,
    1: web3Client,
    56: bscWeb3Client,
    137: polWeb3Client,
    10: opWeb3Client
}

export const scanLinks = {
    8453: 'https://basescan.org/',
    1: 'https://etherscan.io/',
    56: 'https://bscscan.com/',
    137: 'https://polygonscan.com/',
    10: 'https://optimistic.etherscan.io/'
}

export const scanApiLinks = {
    8453: 'https://api.basescan.org/api',
    1: 'https://api.etherscan.io/api',
    56: 'https://api.bscscan.com/api',
    10: 'https://api-optimistic.etherscan.io/api',
    137: 'https://api.polygonscan.com/api'
}

export const apiKeys = {
    8453: 'H5AX1XZR3JSSIJZ3Z2ZMYE48KJFTT3AN8N',
    1: '8SVR8WJRUD64EU8R15K6211PTZIXGCIH8T',
    56: 'CD5PYY7JWHVXHBYN4X21GNHEXYAFDDJU8G',
    137: 'K6VJCTWUHB5N5JCRZWGC1SIT1THSZXPUWR',
    10: 'FY1CHEW9XWVD7K2MM1KTNUWZW6ZTDJASZF'
}

export const publicClient = createPublicClient({
    chain: mainnet,
    transport: http()
})

export const chainNames = {
    1: 'eth',
    8453: 'base',
    56: 'bnb',
    10: 'optimism',
    137: 'polygon'
}

export const chainNames1 = {
    1: 'ethereum',
    8453: 'base',
    56: 'bsc',
    10: 'optimism',
    137: 'polygon'
}

export const coinNames = {
    1: 'ETH',
    8453: 'ETH',
    56: 'BNB',
    10: 'ETH',
    137: 'POL'
}

export const supportedChainIds = [mainnet.id, base.id, bsc.id, polygon.id, optimism.id]

export const chainLogos = {
    8453: '/base.svg',
    1: '/eth.svg',
    56: 'bsc.svg',
    137: '/polygon.svg',
    10: '/optimism.svg'
}

export const feeAmounts = {
    1: 0.04,
    8453: 0.04,
    56: 0.19,
    137: 259,
    10: 0.04,
}

export const initialEth = {
    1: 1.98,
    8453: 1.98,
    56: 9,
    137: 12231,
    10: 1.98,
}

export const ethPriceApiUrl = {
    1: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
    10: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
    8453: 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
    56: 'https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD',
    137: 'https://min-api.cryptocompare.com/data/price?fsym=POL&tsyms=USD',
}

export const imageUrl = 'https://api.shitlordme.me/api/uploads/'

export const apiUrl = 'https://api.shitlordme.me'

export const imageUploadUrl = 'https://api.shitlordme.me/'

export default function formatNumber(number) {
    if (number >= 1000000) {
        return (number / 1000000).toLocaleString() + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toLocaleString() + 'K';
    } else {
        return number.toLocaleString();
    }
}